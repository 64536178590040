import CheckoutPage from '~/components/Checkout';
import Metadata from '~/components/Metadata';
import StartCheckoutPixel from '~/scripts/NBCU/StartCheckoutPixel';

const Checkout = () => {
  return (
    <>
      <Metadata />
      <StartCheckoutPixel />
      <CheckoutPage />
    </>
  );
};

export default Checkout;

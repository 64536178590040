import React from 'react';
import { Script } from 'gatsby';

const StartCheckoutPixel = () => {
  return (
    <Script>
      {` 
      (function (j) {
        var l = 'tvscientific-pix-o-dcdbd912-26e5-447e-a151-6b2a4f77c28b',
          s,
          d,
          w,
          e = encodeURIComponent,
          d = document,
          w = window.location,
          p = d.createElement('IMG');
        s =
          w.protocol +
          '//tvspix.com/t.png?t=' +
          new Date().getTime() +
          '&l=' +
          l +
          '&u3=' +
          e(w.href) +
          '&u1=start_checkout&u4=' +
          new Date().getTime() +
          '&u5=' +
          e(j.lastTouchChannel);
        p.setAttribute('src', s);
        p.setAttribute('height', '0');
        p.setAttribute('width', '0');
        p.setAttribute('alt', '');
        p.style.display = 'none';
        p.style.position = 'fixed';
        d.body.appendChild(p);
      })({
        lastTouchChannel: '', // optional
      });
      `}
    </Script>
  );
};

export default StartCheckoutPixel;
